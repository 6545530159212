import { waitAuth } from "~/utils/middleware";

import { log } from "~/composables/log";
import { waitForPB } from "~/composables/pb";
import { hasAuthorization, RoleRules } from "~/composables/team";

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth();
    await waitForPB();

    if (!hasAuthorization(RoleRules.Params_Teams) || !(__user().hasTeamFullAccess || __user().hasTeamReadOnly)) {
        log("❌ Cannot access teams");
        return navigateTo("/");
    }

    log("✅ Can access teams");
});
